<template>
  <el-row class="movie-list">
    <el-col>
      <el-row>
        <el-table
          :data="dataList"
          :show-header="true"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="createAt"
            label="创建时间"
          />
          <el-table-column
            label="商品信息"
          >
            <template slot-scope="scope">
              <el-row>
                <el-col :md="4">
                  <el-image :src="scope.row.picUrl" min-width="30" height="20" />
                </el-col>
                <el-col :md="20">
                  <router-link target="_blank" style="text-decoration-line: none" :to="`/mall/item?id=${scope.row.itemId}`">
                    <span>{{ scope.row.title }}</span>
                  </router-link>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
            prop="sku"
            label="sku"
          />
          <el-table-column
            label="单价"
          >
            <template slot-scope="scope">
              <span style="color: red">￥{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            label="数量"
          />
          <el-table-column
            label="操作"
          >
            <template slot-scope="scope">
              <el-row>
                <el-button
                  size="mini"
                  @click="getDetail(scope.row)"
                >详情</el-button>
                <el-button
                  size="mini"
                  @click="handleStatus(scope.row)"
                >
                  {{ scope.row.statusText }}
                </el-button>
                <el-button
                  size="mini"
                  @click="deleteOrder(scope.row)"
                >删除</el-button>
              </el-row>
              <el-row>
                <el-button
                  size="mini"
                  @click="handleLogistics(scope.row)"
                >发货</el-button>
                <el-button
                  size="mini"
                  @click="handleProgress(scope.row)"
                >进度</el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :small="screenWidth <= 768"
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="totalSize"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
        />
      </el-row>
    </el-col>

    <el-dialog
      title="支付"
      append-to-body
      :visible.sync="payDialog"
      width="30%"
      center
    />

    <el-dialog
      title="物流"
      append-to-body
      :visible.sync="logisticsDialog"
      width="30%"
      center
    >
      <el-timeline :reverse="false">
        <el-timeline-item
          v-for="(item, index) in logisticsList"
          :key="index"
          :timestamp="item.createAt"
          placement="top"
        >
          <span>{{ item.current }}</span>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>

    <el-dialog
      title="订单发货"
      append-to-body
      :visible.sync="orderLogisticsDialog"
      width="30%"
      center
    >
      <el-form :model="orderLogisticsForm">
        <el-form-item label="物流" label-width="120px" prop="tpl">
          <el-select v-model="logisticsProgressForm.tpl">
            <el-option value="顺丰" label="顺丰" />
            <el-option value="中通" label="中通" />
            <el-option value="韵达" label="韵达" />
          </el-select>
        </el-form-item>
        <el-form-item label="物流订单号" label-width="120px">
          <el-input
            v-model="orderLogisticsForm.logisticsId"
            style="margin-left: 5px"
          />
        </el-form-item>
        <el-button style="float: right; padding: 10px" type="text" @click="submitLogistics">发货</el-button>
      </el-form>
    </el-dialog>

    <el-dialog
      title="物流进度"
      append-to-body
      :visible.sync="logisticsProgressDialog"
      width="30%"
      center
    >
      <el-form :model="logisticsProgressForm">
        <el-form-item label="状态" label-width="120px" prop="subjectId">
          <el-select v-model="logisticsProgressForm.status">
            <el-option value="3" label="运输中" />
            <el-option value="4" label="派送中" />
            <el-option value="5" label="待取件" />
          </el-select>
        </el-form-item>
        <el-form-item label="详情" label-width="120px">
          <el-input
            v-model="logisticsProgressForm.current"
            style="margin-left: 5px"
            type="textarea"
            :rows="5"
          />
        </el-form-item>
        <el-button style="float: right; padding: 10px" type="text" @click="putProgress">提交进度</el-button>
      </el-form>
    </el-dialog>
  </el-row>
</template>

<script>
import { getOrderLogistics, getOrders, putLogisticsProgress, putOrderLogistics } from '@/api/mall'

export default {
  name: 'Order',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 10,
      totalSize: 0,
      dataList: [],
      checked: false,
      searchForm: {
        page: 1,
        path: '/',
        fileType: '0',
        filename: null
      },
      multipleSelection: [],
      totalSelected: 0,
      totalPrice: '0.00',
      productId: null,
      payDialog: false,
      logisticsDialog: false,
      logisticsList: [],
      logisticsProgressDialog: false,
      logisticsProgressForm: {
        orderId: null,
        status: null,
        current: null
      },
      orderLogisticsDialog: false,
      orderLogisticsForm: {
        orderId: null,
        tpl: null,
        logisticsId: null
      }
    }
  },
  created() {
    const path = this.$route.query.productId
    if (path !== undefined && path !== null) {
      this.searchForm.path = path
    }

    document.title = '我的订单'
    this.getData(this.searchForm)
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.searchForm.page = this.currentPage
      this.getData(this.searchForm)
    },
    getData() {
      getOrders(1).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    getDetail(row) {
      this.$message.info('订单 ' + row.orderId + ' 详情')
    },
    deleteOrder(row) {
      this.$message.info(row.orderId)
    },
    handleStatus(row) {
      const status = row.status
      if (status === 1) {
        this.payDialog = true
      } else if (status === 2) {
        getOrderLogistics(row.orderId).then(resp => {
          if (resp.code === 0) {
            this.logisticsDialog = true
            this.logisticsList = resp.data
          }
        })
      } else if (status === 3) {
        this.$message.info('待确认')
      }
    },
    search() {
      this.currentPage = 1
      this.searchForm.page = this.currentPage
      this.getData(this.searchForm)
    },
    handleChange(value) {
    },
    handleLogistics(row) {
      this.orderLogisticsForm.orderId = row.orderId
      this.orderLogisticsDialog = true
    },
    submitLogistics() {
      putOrderLogistics(this.orderLogisticsForm).then(resp => {
        if (resp.code !== 0) {
          this.$notify.warning({
            message: resp.msg,
            duration: 3000
          })
        }
      })

      this.orderLogisticsForm.orderId = null
      this.orderLogisticsForm.tpl = null
      this.orderLogisticsForm.logisticsId = null
      this.orderLogisticsDialog = false
    },
    handleProgress(row) {
      this.logisticsProgressForm.orderId = row.orderId
      this.logisticsProgressDialog = true
    },
    putProgress() {
      putLogisticsProgress(this.logisticsProgressForm).then(resp => {
        if (resp.code !== 0) {
          this.$notify.warning({
            message: resp.msg,
            duration: 3000
          })
        }
      })

      this.logisticsProgressDialog = false
      this.logisticsProgressForm.orderId = null
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
